.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.dot-container {
  display: flex;
  margin-bottom: 5px;
}

.dot-container > div + div {
  margin-left: 15px;
}

.dot {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: orangered;
  border-radius: 50%;
}

.dot1 {
  animation: test 2s infinite;
  animation-delay: 0s;
}
.dot2 {
  animation: test 2s infinite;
  animation-delay: 0.5s;
}
.dot3 {
  animation: test 2s infinite;
  animation-delay: 1s;
}

.main-text {
  font-size: 30px;
  margin-bottom: 5px;
}

.sub-text {
  font-size: 20px;
  margin-bottom: 0px;
}

.blocked {
  color: red;
}

@keyframes test {
  from {opacity: 1;}
  to {opacity: 0;}
}